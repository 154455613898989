import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Group, Col, Section, CtaButton } from "../components/common"

import { faqs } from "../data"
import map from "../images/map.jpg"
import TopContainer from "../components/TopContainer"
import img from "../images/stock/contact1.jpg"

class ContactPage extends Component {
  renderFAQ(item) {
    return (
      <div className="faqItem">
        <h4>{item.question}</h4>
        <p>{item.answer}</p>
        <hr />
      </div>
    )
  }

  renderFAQs() {
    return faqs.map(item => {
      return this.renderFAQ(item)
    })
  }

  renderContacts() {
    const contacts = [
      {
        title: "IP Council",
        name: "Mark Penner",
        company: "Fasken",
        email: "legal@thesfactor.co",
        phone: "+1 416 868 3501",
      },
      {
        title: "Corporate Council",
        name: "John Roberts",
        company: "McInnes Cooper",
        email: "legal@thesfactor.co",
        phone: "+1 902 444 8677",
      },
      {
        title: "Media Inquiries",

        email: "media@thesfactor.co",
        phone: "+1 647 930 9075",
      },
      {
        title: "Investor Relations",

        email: "ir@thesfactor.co",
        phone: "+1 647 930 9075",
      },
    ]

    return contacts.map(contact => {
      return (
        <div className="contact">
          <div className="contact-title">{contact.title}</div>
          {contact.name ? (
            <div className="contact-name">
              {contact.name} - <em>{contact.company}</em>
            </div>
          ) : null}
          <div className="contact-email">
            <a href={`mailto:${contact.email}`}>{contact.email}</a>
          </div>
          <div className="contact-phone">{contact.phone}</div>
        </div>
      )
    })
  }

  renderSocial() {
    const links = [
      {
        name: "LinkedIn",
        url:
          "https://www.linkedin.com/company/maccormick-international-mining-consultancy/",
        icon: "linkedin",
      },
      {
        name: "Twitter",
        url: "https://twitter.com/thesfactorco",
        icon: "twitter",
      },
      {
        name: "Youtube",
        url: "https://www.youtube.com/channel/UC7Z7TLUuPUpmFzyrDz7iAzA",
        icon: "youtube",
      },
      {
        name: "RSS",
        url: "https://therealdirtdotblog.wpcomstaging.com/rss",
        icon: "rss",
      },
    ]

    return links.map(link => {
      return (
        <li>
          <a href={link.url} target="_blank">
            <i className={"fa fa-" + link.icon}></i>
          </a>
        </li>
      )
    })
  }

  render() {
    return (
      <>
        <Layout>
          <SEO
            title="ESG Reporting | Social Impact Data"
            keywords={["ESG Reporting", "Social Impact Data"]}
            description="Contact us for all your requirements related to ESG reporting and social impact data. We can help you fulfill the social impact information gap."
          />
          <TopContainer
            titleLines={["Contact"]}
            body="We are always looking for top talent who can help us to better inform the decision makers who depend on our information."
            ctaText="Careers at The S Factor Co"
            ctaLinkTo={"careers"}
            backgroundImage={img}
          />

          <Section>
            <Group>
              <Col size="third">
                <div className="headingAccent" />
                <h3>Contact Information</h3>
                <div className="contacts">{this.renderContacts()}</div>
              </Col>
              <Col size="third">
                <p style={{ fontSize: 18, lineHeight: 1.2 }}>
                  Email:{" "}
                  <a href="mailto:info@thesfactor.co">info@thesfactor.co</a>
                  <br />
                  <br />
                  Phone: +1.647.930.9075
                  <br />
                  <br />
                  Toll Free: +1.877.276.4822
                  <br />
                  <br />
                </p>
                <ul class="social-links">{this.renderSocial()}</ul>
                <CtaButton
                  href="https://visitor.r20.constantcontact.com/d.jsp?llr=8qhp7l4ab&p=oi&m=8qhp7l4ab&sit=5l9yrlunb&f=cebffb97-eef1-4cc1-bc3a-e4b16d3783bd"
                  target="_blank"
                  dark
                >
                  Subscribe to our Newsletter
                </CtaButton>
                <p></p>
              </Col>
              <Col size="third">
                <img src={map} style={{ width: "100%" }} />
                <p style={{ fontSize: 18, lineHeight: 1.2 }}>
                  401 Bay Street Suite 2702
                  <br />
                  Toronto, Ontario
                  <br />
                  M5H 2Y4
                  <br />
                </p>
              </Col>
            </Group>
          </Section>
          <Section hasDivider={false}>
            {/* <Group>
              <Col size="third">
                <div className="headingAccent" />
                <h3>FAQs</h3>
              </Col>
              <Col size="two-thirds">
                <div style={{ height: 40 }} />
                {this.renderFAQs()}
              </Col>
            </Group> */}
          </Section>
        </Layout>
      </>
    )
  }
}

export default ContactPage
